import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SiteMetadata from '../components/SiteMetadata'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import BlogStyles from '../styles/Blog.module.sass'

/**
 * Display the blog index template
 */
const BlogTemplate = ({ data, pageContext }) => {
  const { title } = SiteMetadata()

  // Pagination data
  const isFirst = pageContext.currentPage === 1
  const isLast = pageContext.currentPage === pageContext.numPages

  const prevPage =
    pageContext.currentPage - 1 === 1
      ? '/'
      : (pageContext.currentPage - 1).toString()
  const nextPage = (pageContext.currentPage + 1).toString()

  return (
    <Layout
      className="is-page-blog hide-contact-form"
      seoTitle={`Plumbing & Heating Blog | ${title}`}
    >
      <div className="section">
        <Hero title="Blog" showCTA="false" />

        <div
          className={`container articles is-semi-narrow ${BlogStyles.container}`}
        >
          <div className="columns is-multiline">
            {data.allMdx.edges.map(edge => {
              return (
                <article
                  className="column is-half-tablet is-one-third-desktop"
                  key={edge.node.id}
                >
                  <Link to={`/${edge.node.fields.slug}`}>
                    <header>
                      {edge.node.fields.image ? (
                        <Img
                          fluid={edge.node.fields.image.childImageSharp.fluid}
                        />
                      ) : null}
                    </header>

                    <h2 className="title is-4">
                      {edge.node.frontmatter.title}
                    </h2>

                    <div>{edge.node.excerpt}</div>

                    <span className="button is-link">Read More</span>
                  </Link>
                </article>
              )
            })}
          </div>
        </div>

        {isFirst && isLast ? null : (
          <div className="container pagination is-semi-narrow">
            <ul className="pagination-list">
              {!isLast && (
                <li>
                  <Link
                    to={`/blog/${nextPage}`}
                    className="pagination-previous button"
                  >
                    Older
                  </Link>
                </li>
              )}
              {!isFirst && (
                <li>
                  <Link
                    to={`/blog/${prevPage}`}
                    className="pagination-next button"
                  >
                    Newer
                  </Link>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default BlogTemplate

export const posts = graphql`
  query($skip: Int!, $limit: Int!) {
    allMdx(
      filter: {
        frontmatter: {
          templateKey: { eq: "article" }
          status: { eq: "publish" }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
